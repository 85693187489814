/* cairo-400 - latin */
@font-face {
    font-family: 'Cairo-Regular';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/cairo/v14/SLXGc1nY6HkvalIhTps.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* roboto-400 - latin */
@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/mdui/1.0.0/fonts/roboto/Roboto-Regular.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body, #root {
    height: 100vh;
    width: 100vw;
    padding: 0px !important;
    z-index: 100;
    margin: 0px !important;
    font-family: 'Roboto', 'Cairo Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-color: #f2f5f8;*/
    background-color: white;
    overflow: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #E4EAF0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #C1C1C4;
    border-radius: 2px;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #9DA6AD;
    }